import React from 'react';
import './Footer.scss';
import govLogo from '../../assets/_scotgov/scottish-government.svg'

const Footer = () => {
    return(
        <>
        <footer className="ds_site-footer">
            <div className="ds_wrapper">
                <div className="ds_site-footer__content">
                    <ul className="ds_site-footer__site-items">
                        <li className="ds_site-items__item">
                            <a href="https://www.mygov.scot/accessibility" target="_blank" rel="noopener noreferrer">Accessibility</a>
                        </li>
                        <li className="ds_site-items__item">
                            <a href="https://www.mygov.scot/contact-social-security-scotland" target="_blank" rel="noopener noreferrer">Contact Us</a>
                        </li>
                        <li className="ds_site-items__item">
                            <a href="https://www.mygov.scot/social-security-data" target="_blank" rel="noopener noreferrer">Privacy</a>
                        </li>
                        <li className="ds_site-items__item">
                            <a href="https://www.socialsecurity.gov.scot/" target="_blank" rel="noopener noreferrer">Social Security Corporate Site</a>
                        </li>
                        <li className="ds_site-items__item">
                            <a href="https://mygov.scot/" target="_blank" rel="noopener noreferrer">mygov.scot</a>
                        </li>
                        <li className="ds_site-items__item">
                            <a href="https://gov.scot/" target="_blank" rel="noopener noreferrer">The Scottish Government</a>
                        </li>
                    </ul>
                    <div className="ds_site-footer__copyright">
                        <p>mygov.scot is the place for people in Scotland to access public services that are easy to find
                            and simple to use.</p>

                    </div>
                    <div className="ds_site-footer__org" />
                </div>
            </div>
        </footer>
        </>
    )
};

export default Footer;

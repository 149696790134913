import './HeaderTitle.scss';

interface HeaderTitleProps {
    title: any; 
}

const HeaderTitle = ({ title }: HeaderTitleProps) => {
    return (
        <>
            <div className="ds_layout__header-pc">
                <header className="ds-page-header">
                    <h1 className="ds-page-header__title">{ title }</h1>
                </header>
            </div>
        </>
    );
}

export default HeaderTitle;
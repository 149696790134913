import { useContext } from "react";
import ChangePostcodePadp from "../ChangePostcode/ChangePostcodePadp";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PostcodeContext from "../../Contexts/PostcodeContext";

const PadpIneligible = () => {
    const postcode = useContext(PostcodeContext);
    let thePostcode = postcode;

    return (
        <>
            <HeaderTitle title={
                <>
                    You cannot apply for<br />
                    Pension Age Disability Payment yet
                </>
            }/>

            <ChangePostcodePadp thePostcode={thePostcode}/>

            <p>You can still <a href="https://www.gov.uk/attendance-allowance" target="_blank">apply for Attendance Allowance (opens in a new tab)
            </a> from the Department for Work and Pensions (DWP) before Pension Age Disability Payment applications open.</p>

            <p>If your application for Attendance Allowance is successful, DWP and Social Security Scotland will move your award without you having to do anything.</p>

            <p>DWP will send you a letter to let you know when:</p>
            <ul>
                <li>your benefit will move to Social Security Scotland</li>
                <li>your Attendance Allowance award will end</li>
            </ul>
        </>
    )
};

export default PadpIneligible;

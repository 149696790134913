import React from "react"
import './_Header.scss';
import logo from '../../assets/_scotgov/social-security-scotland-logo.svg';

const Header = () => (
    <header className="ds_site-header ds_site_header" role="banner">
        <span id="page-top"></span>
        <div className="ds_site-header__top">
            <div className="ds_wrapper">
                <div className="ds_site-header__content">
                    <div aria-label="Social Security Scotland logo" className="ds_site-branding">
                        <img alt="Social Security Scotland logo" id="mygov-logo-img" src={logo}></img>
                    </div>
                </div>
            </div>
        </div>
    </header>
);

export default Header;

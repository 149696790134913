import './index.scss';
import App from "./Components/App/App";
import { createRoot } from 'react-dom/client';
import React from 'react';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
  <App />
  </React.StrictMode>
  );
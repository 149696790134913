import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {BrowserRouter as Router, Route, Routes, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom";
import PostcodeContext from "../../Contexts/PostcodeContext";
import { useState } from "react";
import PadpPostcodeCheck from "../PadpPostcodeCheck/PadpPostcodeCheck";
import UnrecognisedPadp from "../UnrecognisedPadp/UnrecognisedPadp";
import PadpEligible from "../PadpEligible/PadpEligible";
import PadpIneligible from "../PadpIneligible/PadpIneligible";
import PadpOutsideScotland from "../PadpOutsideScotland/PadpOutsideScotland";
import UnrecognisedScotlandEnglandPadp from "../UnrecognisedScotlandEnglandPadp/UnrecognisedScotlandEnglandPadp";

const App = () => {

    //get the postcodeData state using callback function in PostcodeCheck
    const [postcodeData, setPostcodeData] = useState('');


    const updatePostcodeData = (newPostcodeData: any) => {
        setPostcodeData(newPostcodeData);
    };





    return(
        <>
            <PostcodeContext.Provider value={postcodeData}>
                <div className="ds_page__top">
                    <Header />
                </div>
                <div className="ds_page__middle">
                    <div id="body-wrapper" className={"body-wrapper"}>
                        <div className="ds_wrapper">
                            <main id="main-content" className="ds_layout ds_layout--article">
                                <div className="ds_layout__content">
                                    <Router>
                                        <Routes>

                                            <Route path="/" element={
                                                <PadpPostcodeCheck onUpdatePostcode={updatePostcodeData} />
                                            }>
                                            </Route>
                                            <Route path="/eligible" element={
                                                <PadpEligible />
                                            }>
                                            </Route>
                                            <Route path="/ineligible" element={
                                                <PadpIneligible />
                                            }>
                                            </Route>
                                            <Route path="/unrecognised" element={
                                                <UnrecognisedPadp />
                                            }>
                                            </Route>
                                            <Route path="/outside-scotland" element={
                                                <PadpOutsideScotland />
                                            }>
                                            </Route>
                                            <Route path="/unrecognised-scotland-england" element={
                                                <UnrecognisedScotlandEnglandPadp />
                                            }>
                                            </Route >
                                            <Route path="*" element={() => <Navigate to="/" />}>
                                            </Route>
                                        </Routes>
                                    </Router>
                                </div>
                                <div
                                    className="ds_layout__feedback">
                                        <h2 id="feedback">Service feedback</h2>
                                        <a target="_blank" id="feedbackLink"
                                        href="https://response.questback.com/isa/qbv.dll/bylink?p=HXSMYVVeWmJmF93vwa9xaetfvK1PhgQAd3kwWK_uomIgvfp_VotYvXeFTt4fMBD8WxxZ2nRrXelrpXK12o6Qow2">Report
                                    a problem</a> with this online form
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
                <div className="ds_page__bottom">
                    <Footer />
                </div>
            </PostcodeContext.Provider>
        </>
    )
}

export default App

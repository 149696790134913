import './ErrorStyling.scss';

interface ErrorSummaryProps {
    title: string,
    errorText: string
}

const ErrorSummary = ({ title, errorText }: ErrorSummaryProps) => {
    return (
        <>
            <div className="ds_error-summary" id="error-summary" aria-labelledby="error-summary-title" role="alert">
                <h2 className="ds_error-summary__title" id="error-summary-title">{ title }</h2>
                <p>{ errorText }</p>
            </div>
        </>
    );
}

export default ErrorSummary;